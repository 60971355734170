import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import AppSelect from 'app/components/Select';
import AppButton from 'app/components/Button';
import { FiltersType } from 'app/api/reports/types';
import AppPlaceholder from 'app/components/Placeholder/Placeholder';
import useTableau from 'app/hooks/useTableau';
import { trimSpace } from 'app/utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';
import './tableau-filter.scss';

type Props = {
    className?: string;
    filtersFromServer?: FiltersType[];
    isReportsLoading: boolean;

}

const TableauFilter = ({ className, filtersFromServer, isReportsLoading }: Props) => {
    const { handleParametersChange, handleFiltersChange, handleResetFilters, parameters, filters, isFiltersResetLoading } = useTableau();

    const isFiltersLoading = !filters || isReportsLoading;

    useEffect(() => {
        if (!isFiltersLoading && filtersFromServer && filtersFromServer.length) {
            const defaultValueCheck = async () => {
                for await (const filter of filtersFromServer) {
                    if (filter.defaultValue && filter.type === 'parameter') {
                        handleParametersChange(filter.name, { value: filter.defaultValue, formattedValue: filter.defaultValue }, false);
                    } else if (filter.defaultValue && filter.type === 'filter') {
                        handleFiltersChange(filter.name, filter.defaultValue.split(',').map((defaultValue) => ({ value: defaultValue, label: defaultValue })), false);
                    }
                }
            };

            defaultValueCheck();
        }
    }, [isFiltersLoading, filtersFromServer]);

    const renderParameters = (filter: FiltersType) => {
        if (!parameters) return;

        const currentParameter = parameters.find((param) => param.name === filter?.name || '');

        if (!currentParameter) return;

        const splitValues = filter.filterOnly?.split(',');

        // Check if there is a condition to display filter based from values from other filters
        if (splitValues) {
            const checkParameter = parameters.find((param) => param.name === splitValues[0]);

            if (checkParameter?.currentValue.formattedValue !== trimSpace(splitValues[1])) return;
        }

        if (filter.component === 'dropdown') {
            return <div className='mb-6'>
                <label className='form-label fw-bold'>{currentParameter?.name}</label>
                <AppSelect
                    className='app-select-filter-container'
                    classNamePrefix='app-select-filter'
                    value={{ value: currentParameter.currentValue.value, label: currentParameter.currentValue.formattedValue }}
                    onChange={(data) => handleParametersChange(currentParameter.name, { value: data!.value, formattedValue: data!.label })}
                    options={currentParameter.options?.map((option) => ({ value: option.value, label: option.formattedValue }))} />
            </div>;
        } else if (filter.component === 'radio') {
            return <div className='d-flex flex-column mb-6'>
                <label className='form-label fw-bold'>{currentParameter.name}</label>
                {currentParameter?.options.map((param, i) => {
                    return <div key={i} className='my-1'>
                        <label className='form-check form-check-inline form-check-solid me-5 '>
                            <input
                                checked={currentParameter.currentValue.formattedValue === param.formattedValue}
                                className='form-check-input'
                                name={currentParameter.name}
                                type='radio'
                                onChange={() => handleParametersChange(currentParameter.name, param)} />
                            <span className='fw-bold ps-2 fs-6'>{param.formattedValue}</span>
                        </label>
                    </div>;
                })}

            </div>;
        } else if (filter.component === 'datepicker') {
            // convert epoch seconds to date
            const convertedDate = new Date(currentParameter.currentValue.value);

            return <div className='mb-6'>
                <label className='form-label fw-bold'>{currentParameter.name}</label>
                <DatePicker
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    dateFormat={'dd/MM/yyyy'}
                    peekNextMonth={true}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode='select'
                    onChange={(date: Date) => handleParametersChange(currentParameter.name, { value: new Date(date).getTime(), formattedValue: moment(date).format('DD/MM/YYYY') })}
                    selected={convertedDate} />
            </div>;
        } else if (filter.component === 'slider') {
            return <div className='mb-6'>
                <label className='form-label fw-bold'>{currentParameter.name}</label>
                <div className='d-flex'>
                    <input
                        className='slider'
                        step={currentParameter.stepSize}
                        value={currentParameter.currentValue.value}
                        min={currentParameter.minValue?.value || 0}
                        max={currentParameter.maxValue?.value || 0}
                        onChange={(e) => handleParametersChange(currentParameter.name, { value: e.target.value, formattedValue: e.target.value })}
                        type='range' />
                    <span className='ms-3 fs-5 fw-semibold'>{currentParameter.currentValue.value}</span>
                </div>
            </div>;
        }
    };

    const renderFilters = (filter: FiltersType) => {
        if (!filters) return;

        const currentFilter = filters![filter.name];

        if (!currentFilter) return;

        const options = currentFilter.options.map((option) => ({ value: option.value, label: option.formattedValue }));

        if (filter.name === 'Airport Iata Code' && options.length === 1) return;

        if (filter.component === 'dropdown' && currentFilter) {
            return <div className='mb-6'>
                <label className='form-label fw-bold'>{filter.name}</label>
                {currentFilter && <AppSelect
                    className='app-select-filter-container'
                    classNamePrefix='app-select-filter'
                    isMulti={true}
                    value={currentFilter.values ? currentFilter.values.map((option) => ({ value: option.value, label: option.formattedValue })) : null}
                    onChange={(data) => handleFiltersChange(filter.name, data.length ? data : options)}
                    options={options} />}
            </div>;
        }
    };

    return (
        <div className={`${className} tableau-filter`} data-kt-menu='true'>
            <div className='px-7 py-5'>
                {isFiltersLoading
                    ? <AppPlaceholder className='h-30px' customSize='md' numberOfPlaceholder={5}
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12} />
                    : !isFiltersLoading && filtersFromServer && filtersFromServer.length
                        ? <div className='mb-10'>
                            <AppButton className='mb-6' disabled={isFiltersResetLoading} size='sm'
                                onClick={handleResetFilters}>Reset Filters</AppButton>
                            {filtersFromServer.map((filter, i) => {
                                if (filter && filter.type === 'parameter') {
                                    return <div key={i}>{renderParameters(filter)}</div>;
                                } else if (filter && filter.type === 'filter') {
                                    return <div key={i}>{renderFilters(filter)}</div>;
                                }

                                return <div key={i} />;
                            })}
                        </div>
                        : <p className='text-bold fs-5 text-gray-800 text-center'>No available filters</p>}
            </div>
        </div>
    );
};

export default TableauFilter;
