import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { HLSHEmployeeBudget, HLSHEmployeeBudgetUpdate, HLSHEmployees } from './types';

const base = 'hlsh';

// Employee Teams
const GET_HLSH_EMPLOYEES_DATA = 'getHLSHEmployeesData';

export function useGetHLSHEmployeesData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<HLSHEmployees[]>([`${GET_HLSH_EMPLOYEES_DATA}`], `/${base}/employeeteams/spreadsheet`, { ...options, method: 'GET' });
}

export async function addHLSHEmployeeTeam(data: {EMPLOYEE_ID: number, TEAM_NAME: string, TYPE: string}) {
    return await makeApiRequest<boolean>(`/${base}/employeeteams/spreadsheet/addteam`, 'POST', data);
}

export async function deleteHLSHEmployeeTeam(data: {EMPLOYEE_ID: number, TEAM_NAME: string, TYPE: string}) {
    return await makeApiRequest<boolean>(`/${base}/employeeteams/spreadsheet/deleteteam`, 'DELETE', data);
}

export async function updateHLSHSalesperson(data: {EMPLOYEE_ID: number, EMPLOYEE_NAME: string}) {
    return await makeApiRequest<boolean>(`/${base}/employeeteams/spreadsheet/updateSalesperson`, 'POST', data);
}

// Employee Budgets
const GET_HLSH_BUDGET_DATA = 'getHLSHBudgetData';
const GET_HLSH_BUDGET_EMPLOYEE_NAME_OPTIONS = 'getHLSHEmployeeNameOptions';

export function useGetHLSHEmployeeBudgetData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<HLSHEmployeeBudget[]>([`${GET_HLSH_BUDGET_DATA}`], `/${base}/budget/spreadsheet`, { ...options, method: 'GET' });
}

export function useGetHLSHEmployeeBudgetNameOptions({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<HLSHEmployeeBudget[]>([`${GET_HLSH_BUDGET_EMPLOYEE_NAME_OPTIONS}`], `/${base}/budget/spreadsheet/options`, { ...options, method: 'GET' });
}

export async function updateHLSHEmployeeBudget(data: HLSHEmployeeBudgetUpdate[]) {
    return await makeApiRequest<boolean>(`/${base}/budget/spreadsheet/update`, 'POST', data);
}

export async function createHLSHFinancialYear(newFinancialYear: string) {
    return await makeApiRequest<boolean>(`/${base}/budget/spreadsheet/createfinancialyear`, 'POST', null, 'application/json', { newFinancialYear });
}
